import React from 'react';
import FrontFooter from '../../shared/FrontFooter/FrontFooter';
import FrontHeader from '../../shared/FrontHeader/FrontHeader';
import BannerHomepage from '../../HomePage/BannerHomepage';
import cci1 from "../../../assets/images/appImages/collection-1.png";
import cci2 from "../../../assets/images/appImages/collection-2.png";
import cci3 from "../../../assets/images/appImages/collection-3.png";
import image1 from "../../../assets/images/appImages/collection-4.png";
import cs1 from "../../../assets/images/appImages/cs1.png";
import cs2 from "../../../assets/images/appImages/cs2.png";
import cs3 from "../../../assets/images/appImages/cs3.png";
import cs4 from "../../../assets/images/appImages/cs4.png";
import cs5 from "../../../assets/images/appImages/cs5.png";
import cs6 from "../../../assets/images/appImages/cs6.png";
import cs7 from "../../../assets/images/appImages/cs7.png";
import imGroup from "../../../assets/images/appImages/image-group.png";
import Fade from "react-reveal/Fade";
import CollectionCard from './CollectionCard';


const CollectionPage = () => {
    return (
        <div className="landing-page-component">
            <FrontHeader />
            <div className="page-content">
                <BannerHomepage />
                <div className="container-easy-market mb-5">
                    <div className="my-5 collection_item__big">
                        <Fade bottom>
                            <h3 className="pb-4 title-home-page-theme text-center">
                                Collection 2020 - INDIGO
                            </h3>
                        </Fade>
                        <div className="row custom-row">
                            <div className="col-lg-7 mb-3">
                                <div className="image__wrapper">
                                    <img src={image1} alt="image" />
                                </div>
                            </div>
                            <div className="col-lg-5 mb-3">
                                <div className="custom-content">
                                    La collection Indigo de Senartisans célèbre le bleu indigo, symbole culturel africain.
                                    Chaque pièce, fabriquée à partir de draps ​en coton recyclé, allie durabilité et élégance.
                                    Les motifs uniques, teints à la main, reflètent un riche héritage artisanal et ​offrent une
                                    touche d'authenticité et de sophistication à chaque création.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="my-5 collection_item__big gray_sky">
                        <div className="py-5 container">
                            <Fade bottom>
                                <h3 className="pb-4 title-home-page-theme text-center">
                                    Collection 2021 - SAKETT
                                </h3>
                            </Fade>
                            <div className="row custom-row">
                                <div className="col-lg-7 mb-3">
                                    <div className="pb-3 custom-content">
                                        La "saquette" au Sénégal fait référence à une ​technique traditionnelle de teinture artisanale, ​utilisée principalement par les
                                        artisans pour ​créer des motifs uniques sur des tissus. Cette ​méthode de teinture s’inspire des clôtures des ​concessions casamanciennes.
                                    </div>
                                    <div className="row image-row pt-3">
                                        <div className="col md-4 mb-2">
                                            <div className="image__wrapper">
                                                <img src={cs1} alt="image" />
                                            </div>
                                        </div>
                                        <div className="col md-4 mb-2">
                                            <div className="image__wrapper">
                                                <img src={cs2} alt="image" />
                                            </div>
                                        </div>
                                        <div className="col md-4 mb-2">
                                            <div className="image__wrapper">
                                                <img src={cs3} alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 mb-3">
                                    <div className="images__grid">
                                        <div className="image__wrapper">
                                            <img src={cs4} alt="image" />
                                        </div>
                                        <div className="image__wrapper">
                                            <img src={cs5} alt="image" />
                                        </div>
                                        <div className="image__wrapper">
                                            <img src={cs6} alt="image" />
                                        </div>
                                        <div className="image__wrapper">
                                            <img src={cs7} alt="image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="my-5 collection_item__big">
                        <Fade bottom>
                            <h3 className="pb-4 title-home-page-theme text-center">
                                Collection 2024 - TROPICALE
                            </h3>
                        </Fade>
                        <div className="row custom-row">
                            <div className="col-lg-4 mb-3">
                                <div className="image__wrapper">
                                    <img src={imGroup} alt="image" />
                                </div>
                            </div>
                            <div className="col-lg-8 mb-3">
                                <div className="custom-content">
                                    La Collection Tropicale de Senartisans s'inspire de la luxuriance de ​la verdure tropicale et des teintes captivantes d'un coucher de ​soleil.
                                    Cette collection célèbre les couleurs naturelles et vibrantes ​qui évoquent la beauté des paysages tropicaux. <br />
                                    <p>
                                        <strong>Les couleurs de la Collection :</strong>
                                    </p>
                                    <p>
                                        <strong>Saumon :</strong> Évoque la douceur et la chaleur des rayons du soleil couchant.
                                    </p>
                                    <p>
                                        <strong>Vert Olive :</strong> Reflète la fraîcheur et la vitalité de la végétation tropicale.
                                    </p>
                                    <p>
                                        <strong>Jaune :</strong> Apporte une touche de joie et de luminosité, rappelant le soleil éclatant.
                                    </p>
                                    <p>
                                        <strong>Orangé :</strong> Symbolise l'énergie et la chaleur, ajoutant une vivacité à la palette.
                                    </p>
                                    <p>
                                        <strong>Vert Menthe :</strong> Offre une sensation de fraîcheur et de renouveau, rappelant la verdure luxuriante.
                                    </p>
                                    <p>
                                        <strong>Bleu Ciel :</strong> Évoque la tranquillité et la sérénité, rappelant le ciel clair des tropiques.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div style={{ padding: '3rem 0' }}></div>
            <FrontFooter />
        </div>
    )
}

export default CollectionPage;

export const CollectionCards = () => {
    return (
        <>
            <div className="row collection-row">
                {!!data && data?.map((item, key) => (
                    <div className="col-lg-4 col-md-6 mb-4" key={key}>
                        <CollectionCard
                            cover={item?.cover}
                            name={item?.name}
                            date={item?.date}
                            key={item?.id}
                        />
                    </div>
                ))}
            </div>
        </>
    )
}


export const data = [
    {
        id: 1,
        cover: cci1,
        name: "Indigo",
        date: "2020"
    },
    {
        id: 2,
        cover: cci2,
        name: "Sakett",
        date: "2021"
    },
    {
        id: 3,
        cover: cci3,
        name: "Tropicale",
        date: "2024"
    },
];

