import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import Banner from "../../assets/images/appImages/img-banner.png";
import Deco from "../../assets/images/appImages/deco.png";

function BannerHomepage() {
  let location = useLocation();
  let path = location?.pathname;
  return (
    <section className={`
        section-banner-homepage 
        ${path?.startsWith("/collections") && 'collection-banner'}
      `}
    >
      <div className="row align-items-center">
        <div className="col-md-8 col-left-banner-accueil mb-3">
          <div className="content-col-left-banner-accueil">
            <h2 className={`
                mb-0 title-home-page
                ${path?.startsWith("/collections") && 'text-white'}
              `}>
              {path?.startsWith("/collections")
                ? "Nos collections" : "Linge de maison"}
            </h2>
            {!path?.startsWith("/collections") &&
              <h2 className="title-home-page">
                artisanal
              </h2>
            }
            <div className="content-text-home-page-banner mt-2 mb-5">
            </div>
            <div className="container-btn-banner-accueil">
              {!path?.startsWith("/collections") &&
                <NavLink className="btn btn-title-home-page" to="/produits">
                  Voir nos produits
                </NavLink>}
            </div>
          </div>
        </div>
        <div className="col-md-4 col-right-banner-accueil">
          <div className="content-col-right-banner-accueil">
            {path === "/" &&
              <div className="container-img-col-right-banner-accueil">
                <img
                  loading="lazy"
                  src={Banner}
                  alt="Banniere"
                  className="img-col-right-banner-accueil"
                />
              </div>
            }
            <div className="container-img-deco-banner">
              {/*  */}
            </div>
          </div>
        </div>
      </div>
      <div className="blank_filter"></div>
    </section>
  );
}

export default BannerHomepage;
