import React from 'react';

const CollectionCard = (
    { cover, date, name }:
        {
            cover: string,
            date: string,
            name: string
        }
) => {
    return (
        <div className="collection__card">
            <div className="cover__wrapper">
                <img
                    src={cover}
                    alt="image"
                    className="card__image"
                />
            </div>
            <div className="py-4 px-2 card__content">
                <h6 className="collection__title text-center">
                    Collection {date} <br />
                    <span>{name}</span>
                </h6>
            </div>
        </div>
    )
}

export default CollectionCard