import React from "react";
import Logo from "../../../assets/images/appImages/logo-footer.svg";
import { NavLink, Link } from "react-router-dom";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { useGetAllCategoryListQuery } from "../../../utils/api/category/category.api";
import { FiPhoneCall } from "react-icons/fi";
import { useGetProductListQuery } from "../../../utils/api/product/product.api";
import { usePagination, useSearch } from "../../../utils/hooks";
import VersionChecker from "../../../versionChecker/VersionChecker";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTiktok } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { TfiWorld } from "react-icons/tfi";

const FrontFooter = () => {
  const { limit, page, onChange } = usePagination(12);
  const { data, refetch } = useGetAllCategoryListQuery();
  let date = new Date();

  return (
    <footer className="footer-component">
      <div className="container pt-4 pb-3">
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-4 footer-grid ">
            <div className="content-logo-app-footer pb-5">
              <img
                loading="lazy"
                src={Logo}
                alt="logo"
                className="logo-app-footer"
              />
            </div>
            {/* <p className="link-footer-title pb-3">Contactez-nous</p> */}
            <div className="ontact-us-container-footer">
              <div className="footer-contact-us-item mb-3">
                <Link
                  className="decor_item"
                  to="https://wa.me/c/221772390275"
                >
                  <span style={{ color: "#fff", paddingTop: 5 }}>
                    <AiOutlineWhatsApp style={{ fontSize: 18 }} />
                  </span>
                  <span className="link-footer mb-0 pb-0 ps-2">
                    WhatsApp
                  </span>
                </Link>
              </div>
              <div className="footer-contact-us-item">
                <Link
                  className="decor_item"
                  to="tel:+221 77 239 02 75"
                >
                  <span style={{ color: "#fff", paddingTop: 5 }}>
                    <FiPhoneCall style={{ fontSize: 18 }} />
                  </span>
                  <span className="link-footer mb-0 pb-0 ps-2">
                    Téléphone
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="mb-4 col-lg-4 col-md-6 footer-grid">
            <p className="link-footer-title title-footer-deco pb-4">
              Liens utiles
            </p>
            <NavLink
              className="link-footer link-footer-deco trans-0-2 d-block"
              to="/a-propos-de-nous"
            >
              Qui sommes nous ?
            </NavLink>
            <NavLink
              className="link-footer link-footer-deco trans-0-2 d-block"
              to="/politique-de-confidentialite"
            >
              Politique de confidentialité
            </NavLink>
            <NavLink
              className="link-footer link-footer-deco trans-0-2 d-block"
              to="/conditions-generales-de-ventes"
            >
              CGVs
            </NavLink>
          </div>
          <div className="mb-4 col-lg-4 col-md-6 footer-grid">
            <p className="link-footer-title title-footer-deco pb-4">
              Nous contacter
            </p>
            <span className="link-footer link-footer-deco trans-0-2">
              senegaleseartisans@gmail.com
            </span><br />
            <span className="link-footer link-footer-deco trans-0-2">
              Mbour, Sénégal
            </span>
            <p className="m-0 pt-4 social-links-container d-flex align-items-center gap-3">
              <Link
                className="social__wrapper"
                to="https://www.facebook.com/senartisans"
                target="_blank"
                title="Facebook"
              >
                <FaFacebookF />
              </Link>
              <Link
                className="social__wrapper"
                to="https://www.instagram.com/senartisans"
                target="_blank"
                title="Instagram"
              >
                <FaInstagram />
              </Link>
              <Link
                className="social__wrapper"
                to="https://www.tiktok.com/@senartisans"
                target="_blank"
                title="Tiktok"
              >
                <FaTiktok />
              </Link>
            </p>
          </div>
        </div>
        <div className="pt-4 pb-1 copyright-container">
          <div className="separator"></div>
          <div className="pt-3 text-white text-center copyright-text">
            © {date.getFullYear()}, <strong>SENARTISANS</strong>{" "}
            <span>Commerce électronique propulsé par</span>&nbsp;
            <Link
              to={`https://easymarket.sn`}
              target="_blank"
              className="no-link"
            >
              Easymarket.sn
            </Link>
          </div>
        </div>
        <div className="version-view-container text-end text-white fw-bold">
          {"v" + VersionChecker()}
        </div>
      </div>
    </footer>
  );
};

export default FrontFooter;
